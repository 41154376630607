import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Rommelmarkt CGK Zwolle 2022',
        date: moment('2022-07-02 15:42'),
        image: image,
        summary: (
            <>
                Op 2 juli 2022 werd er eindelijk weer een rommelmarkt gehouden.
                Je kon merken dar er tijdens de corona jaren veel mensen de
                zolder al hadden opgeruimd.
            </>
        ),
        content: (
            <>
                <p>
                    Op 2 juli 2022 werd er eindelijk weer een rommelmarkt
                    gehouden. Je kon merken dar er tijdens de corona jaren veel
                    mensen de zolder al hadden opgeruimd. Er werden minder
                    spullen aangeleverd dan voorgaande jaren. Toch wisten we er
                    met elkaar weer een super gezellige dag van te maken, met
                    wat ook belangrijk is voor ons, een mooie opbrengst. Per
                    stichting konden we €277,95 bijschrijven.
                </p>
                <div className="row mt-3">
                    <div className="col-12 col-md-6 mb-2">
                        <img src={image} className="img-fluid img-thumbnail" alt="" />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <img src={image_2} className="img-fluid img-thumbnail" alt="" />
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
