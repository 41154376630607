import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Goede Doelen Maaltijd 2020',
        date: moment('2020-03-03 09:48'),
        image: image,
        summary: (
            <>
                In de Gift City werd weer gekookt voor vele leden van de CGK in
                Zwolle.
            </>
        ),
        content: (
            <>
                <p>
                    In de Gift City werd weer gekookt voor vele leden van de CGK
                    in Zwolle.
                </p>

                <p>
                    De opbrengst was wederom voor de nu 9 Goede Doelen&nbsp;van
                    de CGK en de Gift City. De koks waren al vroeg in de middag
                    aanwezig om de heerlijke stamppotten te bereiden, van
                    ingrediënten die voor het grootste deel gesponsord waren. Al
                    met al was het weer een hele klus om voor grote aantallen te
                    koken. Het werkt tegelijkertijd ook samenbindend, je ziet
                    elkaar weer vanuit een heel andere hoek. Het resultaat mocht
                    er ook deze keer weer zijn. Super hoe op deze manier
                    gezelligheid en lekker eten ook nog eens geld opbrengen voor
                    de verschillende goede doelen. We mochten het mooie bedrag
                    van €417,70 ontvangen.
                </p>

                <img
                    className="img-fluid"
                    width="360"
                    height="480"
                    src={image_2}
                    alt=""
                />
            </>
        ),
    });

export default useStory;
