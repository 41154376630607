import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie CGK 2020',
        date: moment('2021-05-24 13:57'),
        image: image,
        summary: (
            <>
                Ondanks Corona of dankzij Corona zijn er weer heerlijk veel
                oliebollen en Appelflappen gegeten binnen de geledingen van de
                CGK-Zwolle.
            </>
        ),
        content: (
            <>
                <p>
                    Ondanks Corona of dankzij Corona zijn er weer heerlijk veel
                    oliebollen en Appelflappen gegeten binnen de geledingen van
                    de CGK-Zwolle. &nbsp;Cees nam met deze keer, de 25
                    <sup>e</sup>!! afscheid van het organiseren. Ook via deze
                    weg willen we hem heel hartelijk bedanken voor het vele
                    mooie werk dat hij mocht doen en waarin hij heel veel voor
                    de kwetsbare mede mens mocht betekenen.
                </p>

                <p>
                    Inclusief een aantal giften kwamen we per stichting uit op
                    €355,63. Super bedankt allemaal.
                </p>
            </>
        ),
    });

export default useStory;
