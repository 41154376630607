import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Dienstenveiling 2018',
        date: moment('2018-09-20 14:00'),
        image: image,
        summary: (
            <>
                Tijdens het startweekend 2018 van de CGK Zwolle hebben haar
                leden allerlei verschillende diensten aangeboden van taarten
                bakken tot bootuitjes en van werken in de tuin tot bij een ander
                gezellig uit dineren.
            </>
        ),
        content: (
            <>
                <p>
                    Tijdens het startweekend 2018 van de CGK Zwolle hebben haar
                    leden allerlei verschillende diensten aangeboden van taarten
                    bakken tot bootuitjes en van werken in de tuin tot bij een
                    ander gezellig uit dineren.
                </p>

                <p>
                    En niet te vergeten een reis naar Genève. Eigenlijk te veel
                    om op te noemen. Tijdens het Startweekend kon op al deze
                    diensten geboden worden en ook later nog via de site. Deze
                    actie bracht het mooie bedrag van ruim €1600,00 op
                </p>

                <img
                    className="img-fluid"
                    src={image_2}
                    alt=""
                    width="456"
                    height="306"
                />
            </>
        ),
    });

export default useStory;
