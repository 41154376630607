import { Route, Routes } from 'react-router';
import useArticleCollection from './hooks/useArticleCollection';
import { Article } from './hooks/useArticle';
import './index.scss';

function ArticleOverview({ articles }: { articles: Article[] }) {
    const [routes, navs] = useArticleCollection(...articles);

    return (
        <div className="article-collection position-relative">

            <Routes>
                <Route
                    path=""
                    element={
                        <div className="d-flex justify-content-start flex-wrap">
                            {navs}
                        </div>
                    }
                />

                {routes}
            </Routes>
        </div>
    );
}

export default ArticleOverview;
