import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Goede Doelen Maaltijd 2019',
        date: moment('2019-03-04 14:04'),
        image: image,
        summary: (
            <>
                26 januari, 9 en 16 februari 2019 waren de zaterdagen dat er in
                de Gift City weer gekookt werd voor de Goede
                Doelen&nbsp;&nbsp;Maaltijd, een initiatief van de Chr. Ger.
                kerk.De koks waren al vroeg in de middag aanwezig om de
                heerlijke stamppotten te bereiden.
            </>
        ),
        content: (
            <>
                <p>
                    26 januari, 9 en 16 februari 2019 waren de zaterdagen dat er
                    in de Gift City weer gekookt werd voor de Goede
                    Doelen&nbsp;&nbsp;Maaltijd, een initiatief van de Chr. Ger.
                    kerk.De koks waren al vroeg in de middag aanwezig om de
                    heerlijke stamppotten te bereiden.
                </p>

                <p>Een hele klus!</p>

                <p>
                    Heel mooi om te zien hoe vriendengroepen,
                    kringen&nbsp;&nbsp;en families kwamen genieten van het eten
                    en van het gezellig samenzijn.
                </p>

                <p>Als Stichting Sarfath mochten we er ook aan meehelpen.</p>

                <p>
                    Natuurlijk zijn we ook heel blij met de opbrengst van
                    ongeveer € 500.
                </p>
            </>
        ),
    });

export default useStory;
