import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Bodemvoedingsactie 2019',
        date: moment('2019-03-05 14:05'),
        image: image,
        summary: (
            <>
                In februari 2019 hebben we weer onze bodemvoedingsactie gehouden
                in Zwolle en IJsselmuiden.&nbsp;We hebben al een paar jaar
                trouwe afnemers. Daar zijn we heel blij mee.
            </>
        ),
        content: (
            <>
                <p>
                    In februari 2019 hebben we weer onze bodemvoedingsactie
                    gehouden in Zwolle en IJsselmuiden.&nbsp;We hebben al een
                    paar jaar trouwe afnemers. Daar zijn we heel blij mee.
                </p>

                <p>
                    We zijn de familie Koopman dankbaar voor de opslagruimte en
                    Frits en Anneke voor het ophalen van de zakken bodemvoeding.
                </p>

                <p>De meeste en grootste afnemers komen uit Zwolle.</p>

                <p>Met giften erbij kwamen we op een bedrag van ruim €500.</p>

                <p>
                    Bij leven en welzijn hopen we de actie ook volgend jaar weer
                    te houden.
                </p>
            </>
        ),
    });

export default useStory;
