import './index.scss';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import HeaderLinkDivider from '../HeaderLinkDivider';
import HeaderLink, { HeaderLinkDefinition } from '../HeaderLink';
import { useMemo, useState } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import useFocus from '../../hooks/useFocus';

import headerContact from '../../assets/header-contact.jpg';

function Header({ links = [] }: { links?: HeaderLinkDefinition[] }) {
    const { pathname } = useLocation();

    const title = useMemo(
        () =>
            links.find(({ to }) => {
                const path = typeof to !== 'string' ? to.pathname ?? '' : to;
                return matchPath(path, pathname);
            })?.label,
        [links, pathname]
    );
    const headerImage = useMemo(
        () =>
            links.find(({ to }) => {
                const path = typeof to !== 'string' ? to.pathname ?? '' : to;
                return matchPath(path, pathname);
            })?.image ?? headerContact,
        [links, pathname]
    );

    const finalLinks = useMemo(
        () =>
            links.flatMap(({ label, to }, i, a) => [
                <HeaderLink key={label} label={label} to={to} onClick={() => setShowMenu(false)} />,
                ...(i !== a.length - 1
                    ? [<HeaderLinkDivider key={`${label}-divider`} />]
                    : []),
            ]),
        [links]
    );
    const menuLinks = useMemo(
        () =>
            links.flatMap(({ label, to }) => <Link key={label} className="btn btn-light border-bottom rounded-0 px-5" to={to} onClick={() => setShowMenu(false)}>{label}</Link>),
        [links]
    );

    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useFocus<HTMLDivElement>((focus => {
        if (!focus) setShowMenu(false);
    }));

    return (
        <header
            className="app-header d-flex w-100"
            style={{
                backgroundImage: `url(${headerImage})`,
            }}>
            <div className="container d-flex flex-column align-items-start px-5 pt-3">
                <div className="d-flex flex-row justify-content-between w-100">
                    <Link className="btn btn-link" onClick={() => setShowMenu(false)} to="/">
                        <img src={logo} className="app-logo" alt="logo" />
                    </Link>

                    <ul className="d-none d-lg-flex nav flex-fill justify-content-end mb-auto">
                        {finalLinks}
                    </ul>

                    <div className='position-relative d-flex d-lg-none mb-auto justify-content-end'>
                        <button className="btn btn-outline-light border-0" onClick={() => setShowMenu(!showMenu)}>
                            <FontAwesomeIcon icon={faBars} size="2x" />
                        </button>

                        {showMenu ? (
                            <div ref={menuRef} className='position-absolute d-flex flex-column flex-nowrap bg-light mt-5 p-1 rounded border shadow-sm'>
                                {menuLinks}
                            </div>
                        ) : undefined}
                    </div>
                </div>

                <div className="display-4 text-light text-uppercase mb-auto mt-3">
                    {title}
                </div>
            </div>
        </header>
    );
}

export default Header;
