import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Bodemvoeding 2021',
        date: moment('2021-05-24 14:07'),
        image: image,
        summary: (
            <>
                In de maand februari konden de mensen weer aangeven hoeveel
                zakken met Bodemvoeding ze wilden bestellen voor dit jaar.
                Zwolle en IJsselmuiden kwamen samen op 75 zakken uit.
            </>
        ),
        content: (
            <>
                <p>
                    <strong>Actie Bodemvoeding februari/maart 2021</strong>
                </p>

                <p>
                    In de maand februari konden de mensen weer aangeven hoeveel
                    zakken met Bodemvoeding ze wilden bestellen voor dit jaar.
                    Zwolle en IJsselmuiden kwamen samen op 75 zakken uit. Op 6
                    maart zijn alle bestellingen,&nbsp; in verband met Corona,
                    rondgebracht. Samen met een aantal giften, hebben we dan
                    toch weer ruim €450,00 bij elkaar gebracht voor het
                    uitvoeren van projecten.
                </p>

                <p>
                    Zoals het nu lijkt was dit helaas de laatste keer dat we de
                    bodemvoeding geleverd kregen.
                </p>

                <p>
                    We gaan dus opzoek naar een nieuwe actie. Mocht u een idee
                    hebben dan horen we dat natuurlijk graag.
                </p>
            </>
        ),
    });

export default useStory;
