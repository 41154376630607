import './index.scss';

import helpImage1 from '../../../assets/Help1-376x308.jpg';
import helpImage2 from '../../../assets/Help2-376x308.jpg';
import helpImage3 from '../../../assets/Help3-376x308.jpg';
import ImageBlock from '../../ImageBlock';
import { useArticlesTopN } from '../../articles';
import ArticleOverview from '../../ArticleOverview';

function Home() {
    const articles = useArticlesTopN(3);

    return (
        <div className="page-home my-5">
            <div className="container text-start">
                <p className="lead">
                    Stichting Sarfath wil vanuit Bijbels perspectief in Woord en
                    Daad getuige zijn van Jezus Christus in de Arabische wereld.
                    Zij wil dit vooral doen door het investeren in onderwijs aan
                    kinderen en het verder bekwamen van leerkrachten en ouders.
                </p>
                <p className="">
                    Stichting Sarfath is in 2001 opgericht om tegemoet te komen
                    aan de nood die een aantal Nederlandse vrijwilligers onder
                    ogen kwam in de Arabische wereld. Deze vrijwilligers wonen
                    en werken in Arabische landen. Zij leven nog steeds oog in
                    oog met schrijnende situaties en vragen uw hulp (moreel door
                    gebed en financieel door giften) om hen in de gelegenheid te
                    stellen, met milde handen en vriendelijke ogen hulp te
                    verlenen aan de Arabieren en hen op Gods Werk te wijzen.
                </p>
                <p>
                    Onze vrijwilligers helpen in de Arabische wereld op de
                    volgende wijze:
                </p>
                <ul>
                    <li>
                        Zij stimuleren en helpen (financieel) minderbedeelde
                        Arabische gezinnen om hun peuters, kleuters en kinderen
                        (bijzonder) onderwijs te laten volgen.
                    </li>
                    <li>
                        Zij helpen mee het gangbare onderwijssysteem verder te
                        ontwikkelen door het schrijven en introduceren van
                        lesmethoden.
                    </li>
                    <li>
                        Zij geven praktische hulp aan minder validen, anders
                        begaafden en hun familie en anderen (mantelzorg)
                    </li>
                </ul>
                <p className="">
                    Geïnspireerd door Gods Woord geloven wij dat Zijn heil voor
                    alle volken is:
                </p>
                <figure>
                    <blockquote className="blockquote">
                        <p>
                            Ik zal Rahab en Babel vermelden, onder degenen, die
                            Mij kennen; ziet, de Filistijn, en de Tyriër, met de
                            Moor, deze is aldaar geboren. En van Sion zal gezegd
                            worden: Die en die is daarin geboren; en de
                            Allerhoogste Zelf zal hen bevestigen. De Heere zal
                            hen rekenen in het opschrijven der volken, zeggende:
                            Deze is aldaar geboren.
                        </p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Psalm 87:4-6 <cite title="SV77">SV77</cite>
                    </figcaption>
                </figure>
                <p className="">
                    Dat mensen God kunnen leren kennen door de Bijbel:
                </p>
                <figure>
                    <blockquote className="blockquote">
                        <p>
                            Mijn volk gaat te gronde door het gebrek aan kennis.
                        </p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Hosea 4:6 <cite title="SV77">NBG</cite>
                    </figcaption>
                </figure>

                <p className="">
                    Dat we mogen investeren in kinderen zoals Jezus dat deed:
                </p>
                <figure>
                    <blockquote className="blockquote">
                        <p>
                            Daarop brachten de mensen kinderen bij Hem, ze
                            wilden dat Hij hun de handen zou opleggen en zou
                            bidden. Toen de leerlingen hen berispten, zei Jezus:
                            ‘Laat de kinderen ongemoeid, belet ze niet bij mij
                            te komen, want het koninkrijk van de hemel behoort
                            toe aan wie is zoals zij.’ En nadat hij hun de
                            handen had opgelegd, trok hij weer verder.
                        </p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Mattheüs 19: 13-15 <cite title="SV77">NBV</cite>
                    </figcaption>
                </figure>
                <p className="">
                    Waar mensen weer eerlijke en gelijke kansen krijgen om aan
                    ontwikkeling te werken, wordt Gods oorspronkelijke bedoeling
                    met hun levens weer zichtbaar.
                </p>
            </div>

            <div className="d-flex w-100 mt-5 justify-content-between text-center">
                <ImageBlock className="flex-fill" src={helpImage1}>
                    <div>ONDERWIJS</div>
                    <div className="lead">
                        School toegankelijke maken voor kansarme kinderen
                    </div>
                </ImageBlock>
                <ImageBlock className="flex-fill" src={helpImage2}>
                    <div>LESMETHODEN</div>
                    <div className="lead">
                        Schrijven en introduceren van lesmethoden
                    </div>
                </ImageBlock>
                <ImageBlock className="flex-fill" src={helpImage3}>
                    <div>PRAKTISCHE HULP</div>
                    <div className="lead">
                        Hulp voor mindervaliden en andersbegaafden
                    </div>
                </ImageBlock>
            </div>
            <div className="container text-start">
                <div className='mt-5 fs-3'>BLOG</div>
                <div className='display-3'>NIEUWS</div>
                
                <ArticleOverview articles={articles} />
            </div>
        </div>
    );
}

export default Home;
