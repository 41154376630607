import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Verlofperiode 2023',
        date: moment('2023-03-12 13:28'),
        image: image,
        summary: (
            <>
                Begin dit jaar kwam Lisa* voor een korte verlofperiode naar
                Nederland. Even bijtanken, want de situatie werd er in het
                afgelopen jaar niet makkelijker op. Veel veranderingen (in
                personeel en locatie) strubbelingen rondom een vergunning,
                gezondheidsproblemen van medewerkers, maakten het een intensief
                jaar.
            </>
        ),
        content: (
            <>
                <p>
                    Begin dit jaar kwam Lisa* voor een korte verlofperiode naar
                    Nederland. Even bijtanken, want de situatie werd er in het
                    afgelopen jaar niet makkelijker op. Veel veranderingen (in
                    personeel en locatie) strubbelingen rondom een vergunning,
                    gezondheidsproblemen van medewerkers, maakten het een
                    intensief jaar. Gelukkig mochten we ervaren dat God erbij
                    is. En Hij belooft ook dat Hij erbij zal blijven. Het is
                    Zijn werk, waarin Hij mensen wil gebruiken. Op die basis,
                    dit fundament, mag Lisa* in de Arabische wereld samen met de
                    anderen en wij hier, samen met de vele supporters die
                    meehelpen, bidden en geven, doorbouwen aan Zijn Koninkrijk.
                </p>

                <p>
                    In het afgelopen jaar mochten we vanuit Nederland weer veel
                    bijdragen, zie het financiële overzicht.
                </p>

                <p>
                    We danken u allen, die het werk in de Arabische wereld
                    mogelijk maken door gebed, giften en daden.
                </p>

                <p>In Hem verbonden.</p>

                <img
                    className="img-fluid"
                    src={image_2}
                    alt=""
                    width="230"
                    height="101"
                />

                <p>Anneke van Essen, voorzitter</p>
            </>
        ),
    });

export default useStory;
