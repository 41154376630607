import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Speculaasactie Jeruzalemkerk Zwolle 2022',
        date: moment('2022-11-10 18:08'),
        image: image,
        summary: (
            <>
                Met behulp van sponsors, giften en de verkoop van
                speculaasbrokken en –molens, werd er €1.350,00 bijeengebracht.
            </>
        ),
        content: (
            <>
                <div className="row mb-3">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img src={image} className="img-fluid img-thumbnail" alt="" />
                    </div>
                    <div className="col">
                        Vorig jaar was de opbrengst van deze actie nog voor
                        Sarfath en de Oost Europacommissie van de Jeruzalemkerk,
                        maar aangezien de Oost Europacommissie is opgeheven, kon
                        Sarfath alleen aan de slag. Met behulp van sponsors,
                        giften en de verkoop van speculaasbrokken en –molens,
                        werd er €1.350,00 bijeengebracht.
                    </div>
                </div>
                
                <p>
                    <img src={image_2} className="img-fluid" alt="" />
                </p>
            </>
        ),
    });

export default useStory;
