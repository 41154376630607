import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Speculaasactie Jeruzalemkerk Zwolle 2024',
        date: moment('2024-07-13 10:00'),
        image: image,
        summary: (
            <>
                Met elkaar hebben we €1027,00 opgebracht voor het werk van Lisa*
                bij MensRijk, door het kopen van 330 pakken speculaas en/of het
                geven van een gift en sponsoring.
            </>
        ),
        content: (
            <>
                <div className="row mb-3">
                    <div className="col-12 col-xs-6 col-md-4">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        Met elkaar hebben we €1027,00 opgebracht voor het werk
                        van Lisa* bij MensRijk, door het kopen van 330 pakken
                        speculaas en/of het geven van een gift en sponsoring. We
                        willen iedereen bedanken die op welke wijze dan ook
                        hieraan heeft meegeholpen. Door uw bijdrage worden er
                        kinderen, gezinnen en leerkrachten geholpen om meer te
                        worden zoals God ze bedoelt. Lisa* en wij zijn er van
                        overtuigd dat God ook een plan heeft met dit volk, waar
                        een groot deel van de bevolking onder de armoede grens
                        leeft. En waar dit percentage ook ieder jaar stijgt.
                        Soms lijkt het een druppel op een gloeiende plaat, maar
                        in het besef dat God door ons heen wil werken houden we
                        vol. Door uw bijdrage is er al voor vele
                        kinderen/jongeren/gezinnen een blijvend fundament in hun
                        leven gelegd. We danken dan ook God die door u heen
                        werkt.
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
