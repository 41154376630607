import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';
import image_3 from './image_3.png';

const useStory = () =>
    useArticle({
        title: 'Werkbezoek najaar 2016',
        date: moment('2016-12-16 08:38'),
        image: image,
        summary: (
            <>
                In het najaar van 2016 waren wij, Frits en Anneke van Essen uit
                Zwolle, op bezoek in de Arabische wereld. Voordat we op pad
                gingen kregen we van verschillende kanten kaarten, giften en
                goede wensen mee.
            </>
        ),
        content: (
            <>
                <p className="mb-3">
                    In het najaar van 2016 waren wij, Frits en Anneke van Essen
                    uit Zwolle, op bezoek in de Arabische wereld. Voordat we op
                    pad gingen kregen we van verschillende kanten kaarten,
                    giften en goede wensen mee. Ook mochten we een poging doen
                    om zoveel mogelijk speelgoed mee te krijgen in onze bagage.
                    Met ongeveer 75kg speelgoed, 4 kg koffie, raamfolie en nog
                    wat kleine dingen, kwamen we op onze bestemming aan.
                </p>

                <h3>EEN EERSTE INDRUK</h3>

                <p className="mb-3">
                    In een stad met meer inwoners dan in heel Nederland, kregen
                    we gelijk de eerste indrukken van hoe moeizaam het kan zijn
                    om van de ene naar de andere plek te komen. Na een
                    rondleiding bij Mensrijk hebben we met ons tweeën `s middags
                    de toerist uitgehangen, kerken een synagoge en een museum
                    bezocht. Verder is het een intensieve `werkvakantie`
                    geworden, wat overigens ook de bedoeling was.
                </p>

                <img src={image_2} alt="" width="240" height="233" />

                <h3>EEN TOEKOMST GEVEN</h3>

                <p className="mb-3">
                    Samen hebben we teruggeblikt&nbsp;&nbsp;en vooruit gekeken.
                    We hebben&nbsp;&nbsp;verschillende locaties bezocht en ook 2
                    projecten die al ruim 10 jaar, op verschillende locaties in
                    het land, draaien. Bij beide projecten werd er verteld, dat
                    het werk van Karin* het fundament is onder de projecten en
                    zonder haar jaren lange begeleiding zouden deze projecten
                    niet bestaan. Ook hebben we een dag de handen uit de mouwen
                    gestoken.&nbsp;&nbsp;Al met al een ontdekkingsreis. We
                    kregen antwoorden op vragen die we voor dat we op reis
                    gingen hadden en na terugkomst hebben we eigenlijk ook weer
                    nieuwe vragen. Door het werk dat&nbsp;&nbsp;in de afgelopen
                    jaren gedaan is, hebben veel kinderen en volwassenen
                    toekomst gekregen. Om het werk ook in de toekomst te kunnen
                    garanderen, is Mensrijk gestart.
                </p>

                <img width="320" height="214" src={image_3} alt="" />

                <h3>MENSRIJK</h3>

                <p className="mb-3">
                    Een studiecentrum met heel veel verschillende invalshoeken.
                    Educatie, consultancy, boekverkoop, ontmoeting, culturele
                    vorming en het verhuren van lokalen. Op den duur hopen we
                    dat Mensrijk zelf supporting zal zijn, maar op dit moment is
                    onze hulp nog hard nodig. Wij hebben met eigen ogen mogen
                    zien dat er, met al uw bijdragen van de afgelopen jaren,
                    mooie dingen gebeurd zijn. Lesmateriaal, onderwijs aan
                    leerkrachten en kinderen. Als u al die blijde gezichten van
                    kinderen en volwassen zou hebben gezien,&nbsp;&nbsp;dan zou
                    u net als wij beseffen, dat we met elkaar in de afgelopen
                    jaren iets moois hebben neergezet. Met vallen en opstaan,
                    steeds ook weer op andere plekken, heeft God door u en ons
                    heen gewerkt.
                </p>

                <p>
                    <em>*Karin is niet haar werkelijke naam</em>
                </p>
            </>
        ),
    });

export default useStory;
