import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Bodemvoedingsactie 2020',
        date: moment('2020-04-03 09:05'),
        image: image,
        summary: (
            <>
                In februari/maart hebben we weer onze bodemvoedingsactie
                gehouden in Zwolle en IJsselmuiden.&nbsp;We hebben al een paar
                jaar trouwe afnemers. Daar zijn we heel blij mee.{' '}
            </>
        ),
        content: (
            <>
                <p>
                    In februari/maart hebben we weer onze bodemvoedingsactie
                    gehouden in Zwolle en IJsselmuiden.&nbsp;We hebben al een
                    paar jaar trouwe afnemers. Daar zijn we heel blij mee. De
                    laatste bodemvoeding hebben we nu bij de fam. Koopman uit
                    Marknesse opgehaald en rondgebracht. 40% van de opbrengst
                    gaat naar Stichting Antwoord en 60% is bestemd voor de
                    projecten van Sarfath. Met giften erbij kwamen we op een
                    bedrag van ruim €750,00 waarvan dus 273,20 voor Stichting
                    Antwoord bestemd is.
                </p>
            </>
        ),
    });

export default useStory;
