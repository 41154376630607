import { To } from 'react-router';
import './index.scss';
import { Link } from 'react-router-dom';

export interface HeaderLinkDefinition {
    label: string; 
    image?: string;
    to: To;
    onClick?: () => void;
}

function HeaderLink({ label, to, onClick }: HeaderLinkDefinition) {
    return (
        <li className="nav-item">
            <Link className="btn nav-link" to={to} onClick={onClick}>
                {label}
            </Link>
        </li>
    );
}

export default HeaderLink;