import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';
import image_3 from './image_3.jpg';

const useStory = () =>
    useArticle({
        title: 'Stamppotbuffet Jeruzalemkerk 2020',
        date: moment('2020-04-15 09:52'),
        image: image,
        summary: (
            <>
                Voor een verlofperiode was onze zuster terug in Nederland en
                aangezien ze net jarig was geweest en ook nog eens 30 jaren in
                `Verweggistan` werkt, vonden we dit een mooie gelegenheid om
                hier een gezellige maaltijd te houden.
            </>
        ),
        content: (
            <>
                <p>
                    Voor een verlofperiode was onze zuster terug in Nederland en
                    aangezien ze net jarig was geweest en ook nog eens 30 jaren
                    in `Verweggistan` werkt, vonden we dit een mooie gelegenheid
                    om hier een gezellige maaltijd te houden. Samen eten is iets
                    heel moois. Dus in het geheim allerlei mensen uitgenodigd,
                    waaronder ook de gemeenteleden, om samen aan te schuiven bij
                    het stamppotbuffet op zaterdagavond 7 maart. Hier werd
                    enthousiast op gereageerd en er melden zich ook dames en
                    heren aan om te helpen met koken en met de uitvoering. We
                    werden verrast met de aanmelding van 125 personen, wat voor
                    de uitvoering een aardige uitdaging was. Maar met een paar
                    super koks en mensen in de uitvoering die elkaar perfect
                    aanvulden, mogen we terug zien op een heel fijne gezellige
                    avond met mooie ontmoetingen en niet te vergeten een
                    overheerlijk toetje. Ondertussen werd onze zuster verrast
                    door mooie ontmoetingen en verjaardag giften. Na dit
                    heerlijke feest kwam de week erop het sociale leven tot
                    stilstand en kon de feestelijke middag in IJsselmuiden
                    helaas niet meer doorgaan.
                </p>

                <img
                    className="img-fluid"
                    width="557"
                    height="371"
                    src={image_2}
                    alt=""
                />

                <img
                    className="img-fluid"
                    width="558"
                    height="372"
                    src={image_3}
                    alt=""
                />
            </>
        ),
    });

export default useStory;
