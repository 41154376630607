import React, { useEffect, useState } from 'react';
import './index.scss';
import Header from '../Header';
import { Route, Routes, useNavigate } from 'react-router';
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import Donate from '../pages/Donate';
import Projects from '../pages/Projects';
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';

import headerHome from '../../assets/header-home.jpg';
import headerProjects from '../../assets/header-projects.jpg';
import headerContact from '../../assets/header-contact.jpg';
import { useSearchParams } from 'react-router-dom';

function App() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [payment, setPayment] = useState<string>();

    useEffect(() => {
        const payment = searchParams.get('payment');
        if (payment) {
            setPayment(payment);
            navigate('/donate');
        }
    }, [navigate, searchParams])

    
    return (
        <div className="app">
            <Header links={[
                { label: "HOME", to: "/", image: headerHome },
                { label: "BLOG", to: "/blog", image: headerProjects },
                { label: "DONEREN", to: "/donate", image: headerProjects },
                { label: "PROJECTEN", to: "/projects", image: headerProjects },
                { label: "OVER ONS", to: "/about", image: headerContact },
                { label: "CONTACT", to: "/contact", image: headerContact },
            ]} />

            <div className="app-body">
                <Routes>
                    <Route path='/*' element={<Home />} />
                    <Route path='/blog/*' element={<Blog />} />
                    <Route path='/donate' element={<Donate payment={payment} />} />
                    <Route path='/projects' element={<Projects />} />
                    <Route path='/about/*' element={<AboutUs />} />
                    <Route path='/contact' element={<Contact />} />
                </Routes>
            </div>

            <footer className='app-footer' />
        </div>
    );
}

export default App;
 