import { useMemo } from 'react';
import { Article } from './useArticle';
import { sortBy } from 'lodash';

export type ArticleCollection = [routes: JSX.Element[], navs: JSX.Element[]];

function useArticleCollection(...articles: Article[]) {
    const routes = useMemo(() => articles.map(([x]) => x), [articles]);
    const navs = useMemo(() => sortBy(articles, ([,,date]) => -date).map(([,x,]) => x), [articles]);

    return [routes, navs] as ArticleCollection;
}

export default useArticleCollection;
