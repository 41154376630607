import { useRef, useEffect } from 'react';

function useFocus<T extends HTMLElement>(action: (focus: boolean) => void) {
    const ref = useRef<T>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            action(!!ref.current && ref.current.contains(event.target as Node));
        }
        
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [action, ref]);

    return ref;
}

export default useFocus;