import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Goede doelen markt 2017',
        date: moment('2017-04-04 12:58'),
        image: image,
        summary: (
            <>
                De Goede Doelen Markt was weer een super gezellig samen zijn.
                Samen met 7 andere stichtingen binnen de Christelijk
                Gereformeerde Kerk in Zwolle, kunnen we terug zien op een mooie
                dag.
            </>
        ),
        content: (
            <>
                <p>
                    De Goede Doelen Markt was weer een super gezellig samen
                    zijn. Samen met 7 andere stichtingen binnen de Christelijk
                    Gereformeerde Kerk in Zwolle, kunnen we terug zien op een
                    mooie dag.
                </p>

                <p>
                    Een ieder kon zijn of haar Stichting promoten en daarbij
                    spulletjes verkopen voor het Goede Doel. Ook waren er tafels
                    verhuurd aan mensen die daar hun eigen spulletjes aan de man
                    probeerden te brengen. Het weer was ideaal en dat maakte dat
                    we ook veel bezoekers uit de omliggende wijk hadden. De
                    verkoop bij de tafels liep goed en bij het Rad van
                    Avontuur&nbsp; werden mooie prijzen gewonnen. Naast de
                    opbrengst van elke Stichting afzonderlijk, konden we ruim
                    €700,00 verdelen over de 8 stichtingen, dus kunnen we een
                    dezer dagen weer ongeveer €180,00 &nbsp;(ons deel +
                    opbrengst kraam) bijschrijven.
                </p>

                <p>
                    Mocht u de kraam van Stichting Sarfath ook bij een
                    activiteit willen uitnodigen, neemt u dan contact op met het
                    secretariaat: Annie Keizer,&nbsp;
                    <a href="mailto:anniekeizer@hotmail.nl">stich</a>
                    <a href="stichtingsarfath@gmail.com">
                        stichtingsarfath@gmail.com
                    </a>
                    .
                </p>

                <img
                    className="img-fluid"
                    width="300"
                    height="237"
                    src={image_2}
                    alt=""
                />
            </>
        ),
    });

export default useStory;
