import { sortBy } from 'lodash';
import { useMemo } from 'react';
import useArticle_bodemvoeding_2021 from './bodemvoeding_2021';
import useArticle_bodemvoedingsactie_2019 from './bodemvoedingsactie_2019';
import useArticle_bodemvoedingsactie_2020 from './bodemvoedingsactie_2020';
import useArticle_diensten_veiling_cgk_zwolle_2019 from './diensten_veiling_cgk_zwolle_2019';
import useArticle_dienstenveiling_2018 from './dienstenveiling_2018';
import useArticle_goede_doelen_maaltijd_2019 from './goede_doelen_maaltijd_2019';
import useArticle_goede_doelen_maaltijd_2020 from './goede_doelen_maaltijd_2020';
import useArticle_goede_doelen_markt_2016 from './goede_doelen_markt_2016';
import useArticle_goede_doelen_markt_2017 from './goede_doelen_markt_2017';
import useArticle_goede_doelenmaaltijd_2017 from './goede_doelenmaaltijd_2017';
import useArticle_oliebollenactie_2018 from './oliebollenactie_2018';
import useArticle_oliebollenactie_cgk_2020 from './oliebollenactie_cgk_2020';
import useArticle_oliebollenactie_cgk_zwolle_2019 from './oliebollenactie_cgk_zwolle_2019';
import useArticle_oliebollenactie_cgk_zwolle_2023 from './oliebollenactie_cgk_zwolle_2023';
import useArticle_oliebollenactie_jeruzalemkerk_zwolle_2019 from './oliebollenactie_jeruzalemkerk_zwolle_2019';
import useArticle_onbijtactie_valentijnsdag_2021 from './onbijtactie_valentijnsdag_2021';
import useArticle_rommelmarkt_cgk_zwolle_2018 from './rommelmarkt_cgk_zwolle_2018';
import useArticle_rommelmarkt_cgk_zwolle_2019 from './rommelmarkt_cgk_zwolle_2019';
import useArticle_rommelmarkt_cgk_zwolle_2023 from './rommelmarkt_cgk_zwolle_2023';
import useArticle_stamppotbuffet_jeruzalemkerk_2020 from './stamppotbuffet_jeruzalemkerk_2020';
import useArticle_stroopwafelactie_2018 from './stroopwafelactie_2018';
import useArticle_stroopwafelactie_2019 from './stroopwafelactie_2019';
import useArticle_stroopwafelactie_2020 from './stroopwafelactie_2020';
import useArticle_stroopwafelactie_2022 from './stroopwafelactie_2022';
import useArticle_verkoopactie_2020 from './verkoopactie_2020';
import useArticle_verlofperiode_2020 from './verlofperiode_2020';
import useArticle_werkbezoek_najaar_2016 from './werkbezoek_najaar_2016';
import useArticle_stroopwafelactie_cgk_zwolle_2022 from './stroopwafelactie_cgk_zwolle_2022';
import useArticle_rommelmarkt_cgk_zwolle_2022 from './rommelmarkt_cgk_zwolle_2022';
import useArticle_oliebollenactie_cgk_zwolle_2022 from './oliebollenactie_cgk_zwolle_2022';
import useArticle_speculaasactie_jeruzalemkerk_zwolle_2022 from './speculaasactie_jeruzalemkerk_zwolle_2022';
import useArticle_oliebollenactie_jeruzalemkerk_zwolle_2022 from './oliebollenactie_jeruzalemkerk_zwolle_2022';
import useArticle_verlofperiode_2023 from './verlofperiode_2023';
import useArticle_speculaasactie_jeruzalemkerk_zwolle_2024 from './speculaasactie_jeruzalemkerk_zwolle_2024';

function useArticlesLibrary() {
    return [
        useArticle_bodemvoeding_2021(),
        useArticle_bodemvoedingsactie_2019(),
        useArticle_bodemvoedingsactie_2020(),
        useArticle_diensten_veiling_cgk_zwolle_2019(),
        useArticle_dienstenveiling_2018(),
        useArticle_goede_doelen_maaltijd_2019(),
        useArticle_goede_doelen_maaltijd_2020(),
        useArticle_goede_doelen_markt_2016(),
        useArticle_goede_doelen_markt_2017(),
        useArticle_goede_doelenmaaltijd_2017(),
        useArticle_oliebollenactie_2018(),
        useArticle_oliebollenactie_cgk_2020(),
        useArticle_oliebollenactie_cgk_zwolle_2019(),
        useArticle_oliebollenactie_jeruzalemkerk_zwolle_2019(),
        useArticle_onbijtactie_valentijnsdag_2021(),
        useArticle_rommelmarkt_cgk_zwolle_2018(),
        useArticle_rommelmarkt_cgk_zwolle_2019(),
        useArticle_stamppotbuffet_jeruzalemkerk_2020(),
        useArticle_stroopwafelactie_2018(),
        useArticle_stroopwafelactie_2019(),
        useArticle_stroopwafelactie_2020(),
        useArticle_stroopwafelactie_2022(),
        useArticle_verkoopactie_2020(),
        useArticle_verlofperiode_2020(),
        useArticle_werkbezoek_najaar_2016(),
        useArticle_stroopwafelactie_cgk_zwolle_2022(),
        useArticle_rommelmarkt_cgk_zwolle_2022(),
        useArticle_oliebollenactie_cgk_zwolle_2022(),
        useArticle_speculaasactie_jeruzalemkerk_zwolle_2022(),
        useArticle_oliebollenactie_jeruzalemkerk_zwolle_2022(),
        useArticle_verlofperiode_2023(),
        useArticle_rommelmarkt_cgk_zwolle_2023(),
        useArticle_oliebollenactie_cgk_zwolle_2023(),
        useArticle_speculaasactie_jeruzalemkerk_zwolle_2024(),
    ];
}

export function useArticlesTopN(count: number) {
    const articles = useArticlesLibrary();

    const sorted = useMemo(() => sortBy(articles, ([,,date]) => -date), [articles]);
    const filtered = useMemo(() => sorted.filter((x, i) => i < count), [count, sorted]);

    return filtered;
}

export default useArticlesLibrary;
