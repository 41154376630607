import {
    faFile,
    faFilePdf,
    faFileExcel,
    faFileWord,
    faFilePowerpoint,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileZipper,
    faFileCsv,
    faFileBinary,
    faCalendarClock,
    faDownload
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';
import { useMemo } from 'react';

function FileCard({ name, target, contentType }: { name: string; target: string; contentType: string; }) {
    const icon = useMemo(() => {
        if (contentType.startsWith('image/'))
            return faFileImage;
            if (contentType.startsWith('video/'))
                return faFileVideo;
                if (contentType.startsWith('audio/'))
                    return faFileAudio;

        switch (contentType) {
            case 'application/pdf':
                return faFilePdf;
            case 'text/csv':
                return faFileCsv;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
                return faFileWord;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.spreadsheet':
                return faFilePowerpoint;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.presentation':
                return faFileExcel;
            case 'application/zip':
            case 'application/gzip':
            case 'application/x-zip-compressed':
            case 'application/x-7z-compressed':
            case 'application/x-bzip':
            case 'application/x-bzip2':
            case 'application/x-tar':
            case 'application/vnd.rar':
                return faFileZipper;
            case 'text/calendar':
                return faCalendarClock;
            case 'application/octet-stream':
                return faFileBinary;
        }

        return faFile;
    }, [contentType]);

    return (
        <div className="file-card position-relative d-flex flex-column border p-2 m-2">
            <div className="file-preview position-relative d-flex align-items-center justify-content-center border-bottom">
                <FontAwesomeIcon icon={icon} size="4x" />

                <div className='file-card-actions position-absolute m-1'>
                    <a className='btn btn-link p-0' href={target} download>
                        <FontAwesomeIcon icon={faDownload} size="lg" />
                    </a>
                </div>
            </div>
            <div className="d-flex flex-column p-3">
                <h5>{name}</h5>
            </div>
        </div>
    );
}

export default FileCard;
