import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.jpg';

const useStory = () =>
    useArticle({
        title: 'Rommelmarkt CGK Zwolle 2023',
        date: moment('2023-10-11 15:58'),
        image: image,
        summary: (
            <>
                Op 15 juli 2023 vond er een succesvolle rommelmarkt plaats met
                veel verkoop en een gezellige sfeer. Na aftrek van de kosten is
                er bijna €350,00 per stichting opgehaald.
            </>
        ),
        content: (
            <>
                <p>
                    Op zaterdag 15 juli 2023, werden we verblijd met heerlijk
                    weer. Pas op het laatste moment konden we onze verwachtingen
                    positief bijstellen. Genieten dus met elkaar.
                </p>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <img
                            src={image}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                    <div className="col">
                        <p>
                            Op Woensdag- en donderdagavond, zijn er de nodige
                            spullen binnen gebracht en zaterdagmorgen hebben we
                            die met man en macht weer naar buiten gebracht.
                            Behalve de kleding die werd binnen uitgestald.
                        </p>

                        <p>
                            Een koek en zopie tent, heerlijk broodje knakworst
                            of hamburger verhoogden de gezelligheid en de
                            opbrengst. We verwachten een aantal dingen nooit
                            kwijt te raken, maar tot aller verbazing, waren veel
                            van die dingen zo verkocht. De boeken liepen ook als
                            een trein en wat over was ging naar, het
                            Academiehuis in de Grote kerk.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <p>
                            Aan het eind kwam Redt een Kind nog even neuzen en
                            de in hun ogen nog waardevolle spullen ophalen,
                            waarna het overschot naar de ROVA gebracht werd. Dat
                            laatste is altijd het meest lastige van de dag,
                            omdat er vooral ook speelgoed weggaat en andere
                            spullen waar we zeker nog waarde in zien, maar die
                            we blijkbaar aan de straatstenen niet meer kwijt
                            kunnen.
                        </p>

                        <p>
                            We kijken terug op een supergezellige rommelmarkt,
                            met precies genoeg handen om te helpen en een mooie
                            opbrengst. Na aftrek van de kosten komen we op bijna
                            €350,00 per stichting.
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        <img
                            src={image_2}
                            className="img-fluid img-thumbnail"
                            alt=""
                        />
                    </div>
                </div>
            </>
        ),
    });

export default useStory;
