import moment from 'moment';
import useArticle from '../../ArticleOverview/hooks/useArticle';
import image from './image_1.jpg';
import image_2 from './image_2.png';

const useStory = () =>
    useArticle({
        title: 'Verlofperiode 2020',
        date: moment('2020-05-28 09:55'),
        image: image,
        summary: (
            <>
                Dit jaar verloopt de verlofperiode van onze zuster totaal anders
                dan anders. Heeft ze de eerste week heerlijk kunnen uitrusten
                bij familie en de 2<sup>e</sup> en 3<sup>e</sup> week nog mooie
                ontmoetingen gehad en kunnen delen over haar werk, vanaf 11
                maart werd dit anders.
            </>
        ),
        content: (
            <>
                <p>
                    Dit jaar verloopt de verlofperiode van onze zuster totaal
                    anders dan anders. Heeft ze de eerste week heerlijk kunnen
                    uitrusten bij familie en de 2<sup>e</sup> en 3<sup>e</sup>{' '}
                    week nog mooie ontmoetingen gehad en kunnen delen over haar
                    werk, vanaf 11 maart werd dit anders. Alle afspraken werden
                    gecanceld ook al werden sommigen nog via de telefoon
                    afgehandeld. In verband met Covid 19, zijn er geen
                    ontmoetingen meer. Het is zoeken, hoe hier goed mee om te
                    gaan. In plaats van stad en land af te reizen verblijft onze
                    zuster bij familie en is ze weer zeer actief in het
                    uitvoeren van haar werk, ook al is het nu op afstand. Door
                    deze crisis zijn er ook geen vluchten en wordt ongewild haar
                    verblijf in Nederland verlengd.
                </p>

                <p>
                    We beseffen dat al ons doen en laten in Gods hand ligt. Hij
                    is instaat ook uit deze crisis zegen te laten voortkomen. We
                    mogen bidden om Zijn zegen, voor onze zuster en allen die
                    met haar verbonden zijn. Dat het werk tot eer van God en tot
                    zegen voor de mensen om hen heen mag zijn.
                </p>

                <p>
                    Ook zijn we als bestuur dankbaar dat we samen met u mee
                    mogen werken in Gods Koninkrijk. Hoe klein we ook zijn en al
                    lijkt het soms een druppel op een gloeiende plaat: God kan
                    uit het weinige van ons, grote dingen doen. Zo bleef de olie
                    vloeien uit het kruikje van de weduwe van Sarfath en zo
                    mogen ook wij nu nog door u geholpen, blijven geven aan
                    mensen die nog niet beseffen wie God voor hen wil zijn. We
                    danken u allen, die het werk in de Arabische wereld mogelijk
                    maken door gebed, giften en daden.
                </p>

                <p>In Hem verbonden.</p>

                <img
                    className="img-fluid"
                    src={image_2}
                    alt=""
                    width="230"
                    height="101"
                />

                <p>Anneke van Essen, voorzitter</p>
            </>
        ),
    });

export default useStory;
