import useArticle from '../../ArticleOverview/hooks/useArticle';
import moment from 'moment';
import image from './image_1.jpg';

const useStory = () =>
    useArticle({
        title: 'Oliebollenactie CGK Zwolle 2019',
        date: moment('2020-01-02 09:44'),
        image: image,
        summary: (
            <>
                Bestellen kon via de site. Heerlijke oliebollen die zich in de
                voorgaande jaren al bewezen hadden, werden weer volop besteld.
                Met en zonder krenten en ook nog verrukkelijke appelflappen.
            </>
        ),
        content: (
            <>
                <p>
                    Bestellen kon via de site. Heerlijke oliebollen die zich in
                    de voorgaande jaren al bewezen hadden, werden weer volop
                    besteld. Met en zonder krenten en ook nog verrukkelijke
                    appelflappen. Op dinsdagmorgen 31 december gingen er weer
                    velen op pad, waarna al die auto`s nog tijden bleven ruiken
                    naar de oliebollen. Soms zoeken naar een adres maar uit
                    eindelijk zijn alle bollen en flappen op tijd bezorgd, zodat
                    er weer door velen gesmuld is en dan ook nog eens voor de
                    mensen en de activiteiten die gesteund worden door de
                    verschillende Goede Doelen. Al etend mochten we als Sarfath
                    €232,09 ontvangen.
                </p>
            </>
        ),
    });

export default useStory;
